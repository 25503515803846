
(async () => {
    
    const COOKIE_NAME = 'MCN_CALLTRACKING';
    let WIDGET_INSTALL = false;

    const getUtmsParams = (start_with) => {
        return location.search.substr(1)
            .split('&')
            .reduce(function (res, a) {
                const t = a.split('=');
                const vName = decodeURIComponent(t[0]);
                if (vName.startsWith(start_with)) {
                    res[vName] = t.length == 1 ? null : decodeURIComponent(t[1]);
                }
                return res;
            }, {});
    }

    function getCookie(name) {
        let matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name?.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    function setCookie(variable, value, expires_seconds) {
        document.cookie = variable + "=" + value + "; max-age=" + expires_seconds + ";domain=" + location.hostname + ';path=/';
    }

    const showNumber = (number, showNumberParams) => {

        const { selector, containerName, numberByFormat, isOnlyChangeHref } = showNumberParams;

        if (number) {
            if (selector === 'id') {
                let el = document.getElementById(containerName);
                if (el) {
                    if (!isOnlyChangeHref) {
                        el.innerHTML = numberByFormat
                    }

                    if (number.split('')[0] === '8' || number.split('')[0] === '+') {
                        el.setAttribute("href", `tel:${number}`)
                    } else {
                        el.setAttribute("href", `tel:${number.replace(/\s/g, '')}`);
                    }
                }
            } 
            if(selector === 'class'){
                for (const element of document.getElementsByClassName(containerName)) {

                    if (!isOnlyChangeHref) {
                        element.innerHTML = numberByFormat
                    }

                    if (number.split('')[0] === '8' || number.split('')[0] === '+') {
                        element.setAttribute("href", `tel:${number}`)
                    } else {
                        element.setAttribute("href", `tel:${number.replace(/\s/g, '')}`);
                    }
                }
            }
        }
    }

    const setParamsForCachedNumber = (options) => {
        const { rent_duration = 30, number } = options;
        let duration = (number) ? rent_duration : 3600 // (set cache rent) or (set cache block 1 hour)
        setCookie('mcn_params', JSON.stringify(options), duration)   
    }

    const setFirstNumber = (options) => {

        if (options?.number) {
            const { number, timeLeft, cookie } = options;
            setParamsForCachedNumber(options)

            options?.showParams?.forEach((showNumberParams)=>{
                showNumber(number, showNumberParams)
            })

            if (cookie){
                setCookie(COOKIE_NAME, cookie, timeLeft);
            }  
        }
    }

    const setCachedNumber = (params) => {

        const number = params?.number;

        if (number && number !== '0') {
            params?.showParams?.forEach((showNumberParams)=>{
                showNumber(number, showNumberParams)
            })
            console.log('Set the number from the cache');
            return true;
        }
        if (number === '0') {
            console.log('Already visited number - null');
            return true;
        }
        console.log('Error! The number was not found in the cache');
        return false;
    }

    const checkParamsAndNumber = () => {
        const params = getCookie('mcn_params');

        if (params) {
            const paramsObj = JSON?.parse(params)
            if(paramsObj?.number){
                return paramsObj; 
            }
        }
        return false;
    }

    async function setMetrica(clientID, params, visitResponseRent) {
        let visitResponse = await fetch(`https://${__DOMAIN__}/visits/api/public/api/update_metrica`, {
            method: 'post', headers: {
                'Content-Type': 'application/json'
            }, body: JSON.stringify({ ya_client_id: clientID, token: params.token, rent_id: visitResponseRent?.rent_id })
        });
    }

    class MCNTrackerWidget {

        constructor(static_options, isMcnTest) {
            this.setup(static_options, isMcnTest);
        }

        async getMetrics(params, visitResponseRent) {

            let tryCounter = 0;

            function getYandexClientId() {
                if (tryCounter < 10) {
                    if (params.ya_tracker_id && typeof ym === "function") {
                        console.log("Yandex tracker id = true", params.ya_tracker_id);
                        ym(params.ya_tracker_id, 'getClientID', (function (clientID) {
                            console.log(`ya_tracker_client_id=${clientID}`);
                            if (clientID) {
                                console.log(clientID, 'ya_client_id')
                                setMetrica(clientID, params, visitResponseRent)
                                // send 
                                clearInterval(timer)
                            } else {
                                tryCounter + 1
                            }
                        }).bind(this));
                    } else {
                        console.log("Yandex tracker id = false");
                        tryCounter + 1
                    }
                } else {
                    clearInterval(timer)
                }
            }
            const timer = setInterval(getYandexClientId, 6000);
        }

        getReferrer() {
            const urlRefParams = getUtmsParams('referrer')?.referrer || [];
            const documentRef = document?.referrer;
            if (urlRefParams?.length > 0) {
                return urlRefParams
            }
            return documentRef
        }

        async setup(static_options, isMcnTest) {
            let { token } = static_options;
            // Set from cache
            const isParams = checkParamsAndNumber();
            if (isParams && !isMcnTest) {
                setCachedNumber(isParams);
                // Убедится вышли ли мы из виджета
                return false;
            }
            // Get new number
            this.install( { token, __DOMAIN__, isMcnTest } );
        }

        async install(params) {

            if (!WIDGET_INSTALL) {
                WIDGET_INSTALL = true;
            } else {
                console.log("WIDGET ALREADY INSTALLED");
                return;
            }

            const { token, __DOMAIN__, isMcnTest } = params;

            const send = {
                location: document?.location?.href,
                referrer: this.getReferrer(),
                token,
                roistat_visit_id: window?.roistat?.visit || false,
                utms: getUtmsParams('utm'),
                wdg: getUtmsParams('wdg'),
                cookie: getCookie(COOKIE_NAME),
                ya_tracker_id: getCookie('_ym_uid'),
                google_id: getCookie('_ga'),
                user_agent: window?.navigator?.userAgent || 'not agent',
                mcn_params: { isMcnTest },
            };
            // Оставляем по умолчанию RU для старых виджетов без домена
            let visitResponse = await fetch(`https://${__DOMAIN__}/visits/api/public/api/visit`, {
                method: 'post', headers: {
                    'Content-Type': 'application/json'
                }, body: JSON.stringify(send)
            });

            const visitResponseRent = await visitResponse.json();

            const number = visitResponseRent?.number;
            const timeLeft = visitResponseRent?.timeLeft;
            const rent_id = visitResponseRent?.rent_id;

            setFirstNumber({...visitResponseRent, ...params});

            if (number && timeLeft && timeLeft != 10 && params.token && rent_id) {
                await this.getMetrics(params, visitResponseRent);
            }
        }
    }

    //  2 init
    let mcnTrackerWidget = undefined;

    const bots = ["Googlebot", "+https://yandex.com/bots"];

    const checkIsBot = (bots) => {
        const userAgent = window?.navigator?.userAgent;
        var isBool = bots.some(botName => userAgent?.includes(botName));
        return isBool;
    }

    async function prepareWidget(static_options) {

        const isMcnTest = Object.values(getUtmsParams('mcn'))?.length > 0;
        const isBot = checkIsBot(bots);
        if (isBot) {
            console.log('This is bot')
            return false;
        }

        mcnTrackerWidget = new MCNTrackerWidget(static_options, isMcnTest);
        return mcnTrackerWidget;
    }

    window.mcnTrackerWidget = {
        initWidget: (static_options) => document.addEventListener("DOMContentLoaded", prepareWidget(static_options))
    };
})();